.sauce-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin: 2em 2em;
}

.sauce-grid img {
  height: 300px;
}

.sauce-grid a {
  text-decoration: none;
}

@media screen and (min-width: 1100px) and (max-width: 1439px) {
  .sauce-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 768px) and (max-width: 1099px) {
  .sauce-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .sauce-grid {
    grid-template-columns: repeat(2, 1fr);
    margin: 2em 0.5em;
  }
  .sauce-grid img {
    height: 175px;
  }
}
