.sauce-grid a:visited {
  color: rgb(250, 39, 39);
}

/* Dark mode styles */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #1d1d1d;
    color: white;
  }
  .header {
    background-color: #2e2e2e;
  }
  a:hover,
  .sauce-grid a:hover {
    color: white;
  }
  input,
  textarea {
    color: #ffffff;
    background-color: #444444;
    font-size: 16px;
  }
  .icon:hover {
    filter: drop-shadow(0px 0px 15px rgb(255, 120, 0));
    transition: filter 1s;
  }
}

/* Light mode styles */
@media (prefers-color-scheme: light) {
  body {
    background-color: #f2c185;
    color: #5a3a2e;
  }
  .header {
    background-color: #924400;
  }
  a:hover,
  .sauce-grid a:hover {
    color: #5a3a2e;
  }
  input,
  textarea {
    color: #5a3a2e;
    background-color: #f0aa54;
    font-size: 16px;
  }
  .icon {
    filter: brightness(0);
  }
  .icon:hover {
    filter: brightness(0) drop-shadow(0px 0px 15px rgb(255, 120, 0));
    transition: filter 1s;
  }
}

::selection {
  background: #ff7800;
  color: #000;
}

/* Scrollbar */

::-webkit-scrollbar {
  width: 0.75em;
}

::-webkit-scrollbar-track {
  background: #2e2e2e;
}

::-webkit-scrollbar-thumb {
  background: #ff7800;
}

::-webkit-scrollbar-thumb:active {
  background: #914400;
}

.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@font-face {
  font-family: "ChunkFive";
  src: url(./fonts/ChunkFive.otf) format("opentype");
}

/* Button colors */

body {
  background-size: auto;
  padding-top: 50px;
}

button,
.submit-button {
  background-color: #ff7800;
  border: none;
  color: white;
  font-weight: bold;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.5s;
}

button:hover,
.submit-button:hover {
  background-color: #e7e7e7;
  color: black;
  transition: background-color 0.5s;
}

.delete-button:hover,
.cancel-button:hover {
  background-color: #ff2718;
  color: white;
}

.buy-button:hover {
  background-color: #007a18;
  color: white;
}

.edit-button:hover {
  background-color: #0073ff;
  color: white;
}

/* Header colors */

h1,
h2,
h3,
h4 {
  font-family: ChunkFive;
  font-weight: 400;
}

h1 {
  font-size: 45px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 30px;
  margin: 5px;
}

h4 {
  font-size: 22px;
  margin: 5px;
}

/* Link colors */

a {
  color: rgb(255, 120, 0);
  -o-transition: 0.5s;
  -ms-transition: 0.5s;
  -moz-transition: 0.5s;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/* Fire Text */

.fire {
  text-shadow: 0px -2px 4px rgb(230, 146, 0), 0px -2px 10px #ff3,
    0px -10px 20px #f90, 0px -20px 40px #c33;
}

/* Text Inputs */

label {
  padding: 10px;
}

input,
textarea {
  font-size: 16px;
}

input[type="number"] {
  width: 120px;
}

textarea {
  vertical-align: top;
  font-family: Arial, Helvetica, sans-serif;
}

input {
  margin-bottom: 10px;
  margin-top: 10px;
}

input[type="text"],
input[type="url"],
input[type="number"],
textarea {
  padding: 12px 12px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.checkmark {
  height: 20px;
  width: 20px;
  accent-color: rgb(224, 120, 29);
}

@media (max-width: 767px) {
  input[type="url"] {
    width: 250px;
  }
}
