.about-kicker,
.about-header,
.about-text,
.bio-text {
  margin-left: 15%;
  margin-right: 15%;
}

.headshot {
  border-radius: 100%;
  height: 200px;
  width: 200px;
}

.about-text,
.bio-text {
  line-height: 1.4;
}

@media (max-width: 767px) {
  .wordmark {
    height: 60px;
  }
  .headshot {
    height: 150px;
    width: 150px;
  }
  .about-kicker,
  .about-header,
  .about-text,
  .bio-text {
    margin-left: 5%;
    margin-right: 5%;
  }
  .about-kicker {
    font-size: 35px;
  }
  .about-header {
    font-size: 28px;
  }
}

.icon {
  padding: 10px;
  transition: filter 1s;
}
