.splash-image {
  width: 75%;
}

.splash-header {
  margin-block-end: 0px;
  font-size: 50px;
}

.splash-stinger {
  margin-block-start: 0px;
  font-size: 28px;
}

@media (max-width: 767px) {
  .splash-header {
    font-size: 30px;
  }

  .splash-stinger {
    font-size: 20px;
  }

  .splash-header,
  .splash-stinger {
    margin-left: 15px;
    margin-right: 15px;
  }
  .splash-image {
    width: 90%;
  }
}
