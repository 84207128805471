.sauce-name, .scoville-numerical {
  font-family: ChunkFive;
}

.sauce-name {
  font-weight: 400;
  font-size: 25px;
}

ul {
  list-style: none;
  padding: 0;
}

.scoville-card {
  font-size: 30px;
}

.scoville-numerical {
  font-size: 22px;
}

@media (max-width: 767px) {
  .sauce-name {
    font-size: 20px;
  }
}
