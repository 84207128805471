.sauce-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 50px;
}

.sauce-info-name {
  margin: 0px;
}

.sauce-info-description {
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 5%;
  margin-bottom: 5%;
    line-height: 1.4;
}

.sauce-info-image {
  height: 500px;
}

.flames {
  font-size: 60px;
}

.sauce-info-scoville {
  font-size: 28px;
}

.sauce-info-org-kos {
  font-family: ChunkFive;
  font-size: 20px;
}

@media (min-width: 768px) and (max-width: 1279px) {
  .sauce-info-image {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .sauce-info-image {
    height: 300px;
  }
  .sauce-info-grid {
    display: block;
  }
  .sauce-info-name {
    font-size: 35px;
  }
  .flames {
    font-size: 45px;
  }
  .sauce-info-description {
    margin-left: 0%;
    margin-right: 0%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

.scoville-info-icon {
  text-decoration: none;
}