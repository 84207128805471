.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.nav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 25px;
  font-weight: bold;
  padding-top: 10px;
  font-family: ChunkFive;
  font-weight: unset;
}

.nav a:link {
  text-decoration: none;
}

.nav-logo {
  padding-left: 10px;
  transition: filter 1s;
}

.nav-logo:hover {
  filter: drop-shadow(0px 0px 15px rgb(255, 120, 0));
  transition: filter 1s;
}

.nav a:hover {
  color: white;
}

.nav-link {
  padding-top: 15px;
}

@media (max-width: 767px) {
  .nav {
    font-size: 18px;
  }
  .nav-link {
    padding-top: 0px;
    padding-bottom: 5px;
  }
  .nav-logo {
    height: 25px;
    margin-top: 5px;
    margin-left: 5px;
  }
}
